<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div v-show="loading == true" class="alert alert-warning" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error" class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                </div><!-- /alert-danger -->
                <template v-if="!error">
                    <div class="btn-group w-100" role="group" v-if="loading == false">
                        <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                            <i class="fas fa-cogs mr-2" v-show="platform == 'desktop'"></i>
                            <span>Geral</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                            <i class="fas fa-tv mr-2" v-show="platform == 'desktop'"></i>
                            <span>Ao vivo</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 3" :class="{'active': cat == 3}" :disabled="cat == 3">
                            <i class="fas fa-images mr-2" v-show="platform == 'desktop'"></i>
                            <span>Imagens</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 4" :class="{'active': cat == 4}" :disabled="cat == 4">
                            <i class="fas fa-plus mr-2" v-show="platform == 'desktop'"></i>
                            <span>Mais</span>
                        </button>
                    </div><!-- /btn-group -->
                    <div class="mt-3 mb-3" v-if="loading == false">
                        <form @submit.prevent="save">
                            <template v-if="cat == 1">
                                <ul class="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" @click="cfg_step = 0" :class="{'active': cfg_step == 0}">Configurações</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" @click="cfg_step = 1" :class="{'active': cfg_step == 1}">Criar aposta</a>
                                    </li>
                                </ul>
                                <template v-if="cfg_step == 0">
                                    <div class="form-group">
                                        <label class="text-light">Nome da banca</label>
                                        <input type="text" class="form-control" v-model="configs.nome" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Bloquear sistema</label>
                                        <select class="form-control" v-model="configs.bloquear">
                                            <option value="1" :selected="configs.bloquear == 1">Sim</option>
                                            <option value="2" :selected="configs.bloquear == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Multiplicar valor apostado</label>
                                        <input type="text" class="form-control" v-model="configs.multiplicador" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Mínimo de jogos por aposta</label>
                                        <input type="text" class="form-control" v-model="configs.min_jogos" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Máximo de jogos por aposta</label>
                                        <input type="text" class="form-control" v-model="configs.max_jogos" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor mínimo para realizar apostas</label>
                                        <input type="text" class="form-control" v-model="configs.min_valor" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor máximo de pagamento da banca</label>
                                        <input type="text" class="form-control" v-model="configs.max_valor" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor máximo permitido para um palpite</label>
                                        <input type="text" class="form-control" v-model="configs.max_valor_um_palpite" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor máximo permitido para dois palpites</label>
                                        <input type="text" class="form-control" v-model="configs.max_valor_dois_palpites" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor máximo permitido para três ou mais palpites</label>
                                        <input type="text" class="form-control" v-model="configs.max_valor_mais_palpites" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Cotação mínima para realizar aposta com um palpite</label>
                                        <input type="text" class="form-control" v-model="configs.min_valor_odd_um_palpite" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Cotação mínima para realizar aposta com dois ou mais palpites</label>
                                        <input type="text" class="form-control" v-model="configs.min_valor_odd_mais_palpites" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Cotação com o valor menor que R$ {{configs.min_odd ? configs.min_odd : '1.01'}} serão bloqueadas</label>
                                        <input type="text" class="form-control" v-model="configs.min_odd" autocomplete="off" spellcheck="false">
                                    </div>
                                    <!-- <div class="form-group">
                                        <label class="text-light">Permitir realizar apostas com centavos</label>
                                        <select class="form-control" v-model="configs.permitir_centavos">
                                            <option value="1" :selected="configs.permitir_centavos == 1">Sim</option>
                                            <option value="2" :selected="configs.permitir_centavos == 2">Não</option>
                                        </select>
                                    </div> -->
                                    <div class="form-group">
                                        <label class="text-light">Permitir que os gerentes excluam apostas</label>
                                        <select class="form-control" v-model="configs.gerente_excluir_aposta">
                                            <option value="1" :selected="configs.gerente_excluir_aposta == 1">Sim</option>
                                            <option value="2" :selected="configs.gerente_excluir_aposta == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Permitir que os vendedores excluam apostas</label>
                                        <select class="form-control" v-model="configs.vendedor_excluir_aposta">
                                            <option value="1" :selected="configs.vendedor_excluir_aposta == 1">Sim</option>
                                            <option value="2" :selected="configs.vendedor_excluir_aposta == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Tempo para que os gerentes e vendedores excluam apostas</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">Minutos</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="configs.excluir_aposta_tempo" :readonly="configs.gerente_excluir_aposta == 2 && configs.vendedor_excluir_aposta == 2" autocomplete="off" spellcheck="false">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Exibir campeonato na impressão do comprovante</label>
                                        <select class="form-control" v-model="configs.exibir_campeonato">
                                            <option value="1" :selected="configs.exibir_campeonato == 1">Sim</option>
                                            <option value="2" :selected="configs.exibir_campeonato == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Destacar campeonato na impressão com tarja preta</label>
                                        <select class="form-control" v-model="configs.destacar_campeonato">
                                            <option value="1" :selected="configs.destacar_campeonato == 1">Sim</option>
                                            <option value="2" :selected="configs.destacar_campeonato == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Exibir telefone do vendedor no comprovante</label>
                                        <select class="form-control" v-model="configs.exibir_telefone_vendedor">
                                            <option value="1" :selected="configs.exibir_telefone_vendedor == 1">Sim</option>
                                            <option value="2" :selected="configs.exibir_telefone_vendedor == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Comissão do vendedor para apostas premiadas</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">%</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="configs.comissao_premio" autocomplete="off" spellcheck="false">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Mensagem informativa do final do comprovante</label>
                                        <textarea class="form-control" v-model="configs.mensagem" rows="3" autocomplete="off" spellcheck="false"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Termos e regulamento da sua banca</label>
                                        <textarea class="form-control" v-model="configs.regras" rows="10" autocomplete="off" spellcheck="false"></textarea>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="form-group">
                                        <label class="text-light">Ativar função "Criar aposta"</label>
                                        <select class="form-control" v-model="configs.betbuilder">
                                            <option value="sim" :selected="configs.betbuilder == 'sim'">Sim</option>
                                            <option value="nao" :selected="configs.betbuilder == 'nao'">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Mínimo de jogos</label>
                                        <input type="text" class="form-control" v-model="configs.betbuilder_min_jogos" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Máximo de jogos</label>
                                        <input type="text" class="form-control" v-model="configs.betbuilder_max_jogos" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Mínimo de seleção por jogo</label>
                                        <input type="text" class="form-control" v-model="configs.betbuilder_min_palpites" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Máximo de seleção por jogo</label>
                                        <input type="text" class="form-control" v-model="configs.betbuilder_max_palpites" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Permitir "Criar aposta" no ao vivo</label>
                                        <select class="form-control" v-model="configs.betbuilder_live">
                                            <option value="sim" :selected="configs.betbuilder_live == 'sim'">Sim</option>
                                            <option value="nao" :selected="configs.betbuilder_live == 'nao'">Não</option>
                                        </select>
                                    </div>
                                    <div class="card mb-3">
                                        <div class="card-header">Ativar ou desativar mercados</div>
                                        <div class="card-body">
                                            <div class="card rounded" v-for="(market, index) in betBuilderOptions.options" :class="[{'mt-3': index > 1}, selectedOptionMarket(index) ? 'bg-success text-white' : 'bg-danger text-white']" :key="index">
                                                <div class="card-body">
                                                    <div class="row align-items-center">
                                                        <div class="col">{{ market.label }}</div>
                                                        <div class="w-100 border-top mt-3 pb-3" v-if="platform == 'mobile'"></div>
                                                        <div :class="platform == 'desktop' ? 'col' : 'col-12'">
                                                            <div class="d-flex align-items-center justify-content-between w-100" :class="platform == 'desktop' ? 'flex-row' : 'flex-column'">
                                                                <div class="mr-2">Reduzir (%)</div>
                                                                <div :class="{'w-100': platform == 'mobile'}">
                                                                    <input class="form-control form-control-sm w-100" type="text" v-model="market.percent" v-number-only @paste="noPaste($event)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w-100 mb-3" v-if="platform == 'mobile'"></div>
                                                        <div :class="platform == 'desktop' ? 'col' : 'col-12'">
                                                            <a class="btn btn-block btn-sm" :class="selectedOptionMarket(index) ? 'btn-danger' : 'btn-success'" href="javascript:;" role="button" @click="selectOptionMarket(index)">
                                                                <template v-if="selectedOptionMarket(index)">
                                                                    <div class="d-flex flex-row align-items-center justify-content-center">
                                                                        <div class="mr-2">
                                                                            <i class="fas fa-ban"></i>
                                                                        </div>
                                                                        <div>Desativar</div>
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="d-flex flex-row align-items-center justify-content-center">
                                                                        <div class="mr-2">
                                                                            <i class="fas fa-check-circle"></i>
                                                                        </div>
                                                                        <div>Ativar</div>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-else-if="cat == 2">
                                <ul class="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" @click="nav_step = 0" :class="{'active': nav_step == 0}">Configurações</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" @click="nav_step = 1" :class="{'active': nav_step == 1}">Cotações (odds)</a>
                                    </li>
                                </ul>
                                <template v-if="nav_step == 0">
                                    <div class="form-group">
                                        <label class="text-light">Permitir jogos ao vivo</label>
                                        <select class="form-control" v-model="configs.ao_vivo">
                                            <option value="1" :selected="configs.ao_vivo == 1">Sim</option>
                                            <option value="2" :selected="configs.ao_vivo == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Tempo máximo para apostas</label>
                                        <input type="text" class="form-control" v-model="configs.ao_vivo_tempo" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Delay mínimo do ao vivo (segundos)</label>
                                        <input type="text" class="form-control" v-model="configs.ao_vivo_delay" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Mínimo de jogos por aposta</label>
                                        <input type="text" class="form-control" v-model="configs.ao_vivo_min_jogos" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Máximo de jogos por aposta</label>
                                        <input type="text" class="form-control" v-model="configs.max_jogos_ao_vivo" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor mínimo para realizar apostas</label>
                                        <input type="text" class="form-control" v-model="configs.min_valor_ao_vivo" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor máximo de pagamento da banca</label>
                                        <input type="text" class="form-control" v-model="configs.max_valor_ao_vivo" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Cotação mínima para realizar aposta com um palpite</label>
                                        <input type="text" class="form-control" v-model="configs.min_valor_odd_um_palpite_ao_vivo" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Cotação mínima para realizar aposta com dois ou mais palpites</label>
                                        <input type="text" class="form-control" v-model="configs.min_valor_odd_mais_palpites_ao_vivo" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Cotação com o valor menor que R$ {{configs.min_odd_ao_vivo ? configs.min_odd_ao_vivo : '1.01'}} serão bloqueadas</label>
                                        <input type="text" class="form-control" v-model="configs.min_odd_ao_vivo" autocomplete="off" spellcheck="false">
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="w-100 clearfix mb-3" :class="{'desktop': platform == 'desktop'}">
                                        <div class="list-group list-group-flush rounded">
                                            <div class="list-group-item " v-for="(item, index) in configs.ao_vivo_opcoes" :key="index" :class="{'bg-danger text-white': String(configs.ao_vivo_opcoes[index].ativo) == '2'}">
                                                <div class="row" :class="{'justify-content-center': platform == 'mobile'}">
                                                    <div class="col text-primary" :class="{'col-12 mb-2 text-center': platform == 'mobile', 'd-flex align-items-center text-truncate': platform == 'desktop', 'cursor-pointer': (item.id == 100 || item.id == 101 || item.id == 102)}" @click="toggleShowRow(item.id)">
                                                        <div :class="{'text-truncate': platform == 'desktop', 'bg-cat rounded p-1': platform == 'mobile', 'text-white': String(configs.ao_vivo_opcoes[index].ativo) == '2'}">
                                                            <span>{{ item.nome }}</span>
                                                            <template v-if="item.id == 100 || item.id == 101 || item.id == 102">
                                                                <i class="fas ml-2" :class="showRowID == item.id ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                        <div class="d-flex flex-row align-items-center">
                                                            <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Tipo</div>
                                                            <div :class="{'w-50': platform == 'mobile'}">
                                                                <select class="form-control form-control-sm" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" v-model="configs.ao_vivo_opcoes[index].tipo">
                                                                    <option value="1" :selected="item.tipo == 1">Reduzir</option>
                                                                    <option value="2" :selected="item.tipo == 2">Aumentar</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                        <div class="d-flex flex-row align-items-center">
                                                            <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">%</div>
                                                            <div :class="{'w-50': platform == 'mobile'}">
                                                                <input class="form-control form-control-sm" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" type="text" :id="`percent-${index}`" v-model="configs.ao_vivo_opcoes[index].porcentagem" v-number-only @paste="noPaste($event)"> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                        <div class="d-flex flex-row align-items-center">
                                                            <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Limite</div>
                                                            <div :class="{'w-50': platform == 'mobile'}">
                                                                <input class="form-control form-control-sm w-limit" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" type="text" :id="`limit-${index}`" v-model="configs.ao_vivo_opcoes[index].limite" v-number-only @paste="noPaste($event)"> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                        <div class="d-flex flex-row align-items-center">
                                                            <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Bloquear</div>
                                                            <div :class="{'w-50': platform == 'mobile'}">
                                                                <select class="form-control form-control-sm" :id="`active-${index}`" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" v-model="configs.ao_vivo_opcoes[index].ativo">
                                                                    <option value="1" :selected="item.ativo == 1">Não</option>
                                                                    <option value="2" :selected="item.ativo == 2">Sim</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><!-- /row -->
                                                <template v-if="item.id == 100 || item.id == 101 || item.id == 102">
                                                    <div class="card p-2 mt-2 bg-f1f1f1" v-show="showRowID == item.id">
                                                        <div class="row align-items-center">
                                                            <div class="text-truncate text-danger" :class="platform == 'desktop' ? 'col' : 'col-12'">{{ item.id == 100 || item.id == 102 ? 'Remover escanteios menores que:' : 'Remover escanteios maiores que:' }}</div>
                                                            <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-2'">
                                                                <select :id="`blacklist-${index}`" v-model="configs.ao_vivo_opcoes[index].blacklist" class="form-control form-control-sm">
                                                                    <option v-for="n in 21" :value="n-1" :key="n-1" :selected="item.blacklist == (n-1)">{{n-1}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div><!-- /list-group-item -->
                                        </div><!-- /list-group list-group-flush -->
                                    </div><!-- /w-100 clearfix-->
                                </template>
                            </template>
                            <template v-else-if="cat == 3">
                                <ul class="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" @click="upload_step = 0" :class="{'disabled': upload_progress, 'active': upload_step == 0}">Slider</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" @click="upload_step = 1" :class="{'disabled': upload_progress, 'active': upload_step == 1}">Popup</a>
                                    </li>
                                </ul>
                                <template v-if="upload_step == 0">
                                    <div class="card text-center mb-3">
                                        <div class="card-header">Slider</div>
                                            <div class="card-body">
                                            <h5 class="card-title">Envie suas imagens</h5>
                                            <p class="card-text">Essas são as dimensões das imagens do slider: <b class="text-danger">597x250</b> px</p>
                                            <div class="card-columns">
                                                <div class="card" v-for="(item, index) in configs.slider" :key="index">
                                                    <img :src="item.image" class="card-img-top" alt="">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col" :class="{'col-12': platform == 'mobile'}">
                                                                <a class="btn btn-danger btn-block text-white" role="button" @click="deleteImage(index)"><i class="fas fa-trash-alt mr-2"></i>Excluir</a>
                                                            </div>
                                                            <div class="col" :class="{'col-12 mt-3': platform == 'mobile'}">
                                                                <a class="btn btn-secondary btn-block text-white" role="button" @click="linkImage(index)"><i class="fas fa-link mr-2"></i>Link</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><!-- /card -->
                                            </div><!-- /card-columns -->
                                        </div>
                                        <div class="card-footer">
                                            <div class="progress mb-3" style="height: 20px;" v-show="upload_step == 0 && upload_progress == true">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" :style="{'width': progress + '%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                                            </div>
                                            <div class="form-group mb-0" role="file">
                                                <div class="row" :class="{'pl-3 pr-3': platform == 'mobile'}">
                                                    <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                        <label class="btn btn-primary btn-block mb-0" for="image-upload" :disabled="upload_progress == true">
                                                            <input type="file" accept=".gif, .jpg, .jpeg, .png" id="image-upload" @change="upload">
                                                            <i class="fas fa-upload mr-2"></i>
                                                            <span>Enviar imagem</span>
                                                        </label>
                                                    </div><!-- /col -->
                                                    <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                        <select class="form-control" v-model="configs.slider_exibir" disabled :class="{'mt-3': platform == 'mobile'}">
                                                            <option value="1" :selected="configs.slider_exibir == 1">Exibir (obrigatório)</option>
                                                            <!-- <option value="1" :selected="configs.slider_exibir == 2">Não exibir</option> -->
                                                        </select>
                                                    </div>
                                                </div><!-- /row -->
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="card text-center mb-3">
                                    <div class="card-header">Popup</div>
                                        <div class="card-body">
                                            <h5 class="card-title">Envie sua imagem</h5>
                                            <p class="card-text">Você só pode enviar uma imagem com o tamanho: <b class="text-danger">250x400</b> px</p>
                                            <div class="card-columns">
                                                <div class="card" v-for="(item, index) in configs.popup_items" :key="index">
                                                    <img :src="item.image" class="card-img-top" alt="">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col" :class="{'col-12': platform == 'mobile'}">
                                                                <a class="btn btn-danger btn-block text-white" role="button" @click="deleteImage(index)"><i class="fas fa-trash-alt mr-2"></i>Excluir</a>
                                                            </div>
                                                            <div class="col" :class="{'col-12 mt-3': platform == 'mobile'}">
                                                                <a class="btn btn-secondary btn-block text-white" role="button" @click="linkImage(index)"><i class="fas fa-link mr-2"></i>Link</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><!-- /card -->
                                            </div><!-- /card-columns -->
                                        </div>
                                        <div class="card-footer">
                                            <div class="progress mb-3" style="height: 20px;" v-show="upload_step == 1 && upload_progress == true">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" :style="{'width': progress + '%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                                            </div>
                                            <div class="form-group mb-0" role="file">
                                                <div class="row" :class="{'pl-3 pr-3': platform == 'mobile'}">
                                                    <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                        <label class="btn btn-primary btn-block mb-0" for="image-upload" :disabled="upload_progress == true">
                                                            <input type="file" accept=".gif, .jpg, .jpeg, .png" id="image-upload" @change="upload">
                                                            <i class="fas fa-upload mr-2"></i>
                                                            <span>Enviar imagem</span>
                                                        </label>
                                                    </div><!-- /col -->
                                                    <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                        <select class="form-control" v-model="configs.popup_exibir" :class="{'mt-3': platform == 'mobile'}">
                                                            <option value="sim" :selected="configs.popup_exibir == 'sim'">Exibir</option>
                                                            <option value="nao" :selected="configs.popup_exibir == 'nao'">Não exibir</option>
                                                        </select>
                                                    </div>
                                                </div><!-- /row -->
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <ul class="nav nav-pills mb-3 myTabConfigs" :class="{'nav-justified': platform == 'desktop', 'flex-column': platform == 'mobile'}" id="myTabConfigs" role="tablist">
                                    <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                        <a class="nav-link active" :class="{'rounded-left': platform == 'desktop', 'rounded': platform == 'mobile'}" id="first-tab" data-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">Travas</a>
                                    </li>
                                    <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                        <a class="nav-link" :class="{'rounded-0': platform == 'desktop', 'rounded': platform == 'mobile'}" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false" @click="getBanks">Cliente</a>
                                    </li>
                                    <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                        <a class="nav-link" :class="{'rounded-0': platform == 'desktop', 'rounded': platform == 'mobile'}" id="third-tab" data-toggle="tab" href="#third" role="tab" aria-controls="third" aria-selected="false">Redes sociais</a>
                                    </li>  
                                    <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                        <a class="nav-link" :class="{'rounded-0': platform == 'desktop', 'rounded': platform == 'mobile'}" id="fourth-tab" data-toggle="tab" href="#fourth" role="tab" aria-controls="fourth" aria-selected="false">Encerrar aposta</a>
                                    </li>
                                    <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                        <a class="nav-link" :class="{'rounded-0': platform == 'desktop', 'rounded': platform == 'mobile'}" id="fifth-tab" data-toggle="tab" href="#fifth" role="tab" aria-controls="fifth" aria-selected="false">Loja</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{'rounded-right': platform == 'desktop', 'rounded': platform == 'mobile'}" id="six-tab" data-toggle="tab" href="#six" role="tab" aria-controls="six" aria-selected="false">Cassino</a>
                                    </li>                              
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                        <div class="form-group">
                                            <div class="row align-items-center">
                                                <div :class="platform == 'desktop' ? 'col' : 'col-12'">
                                                    <div class="d-flex flex-column">
                                                        <div class="text-light">Data para travar os eventos</div>
                                                        <div class="mt-2">
                                                            <input type="date" class="form-control" v-model="configs.data_trava">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-3'">
                                                    <div class="d-flex flex-column">
                                                        <div class="text-light">Hora para travar os eventos</div>
                                                        <div class="mt-2">
                                                            <input type="time" class="form-control" v-model="configs.hora_trava">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-3'">
                                                    <a class="btn btn-block btn-success" role="button" @click="releaseGames" :class="{'disabled': disabledButton}">
                                                        <i class="fas fa-calendar-check mr-2"></i>Liberar jogos
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light">Exibir eventos até</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">Dias</div>
                                                </div>
                                                <input type="text" class="form-control" v-model="configs.exibir_eventos_limite" autocomplete="off" spellcheck="false">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light">Liberar eventos automático</label>
                                            <select class="form-control" v-model="configs.liberar_eventos_automatico">
                                                <option value="sim" :selected="configs.liberar_eventos_automatico == 'sim'">Sim</option>
                                                <option value="nao" :selected="configs.liberar_eventos_automatico == 'nao'">Não</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light">Exibir caixa do vendedor até</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">Dias</div>
                                                </div>
                                                <input type="text" class="form-control" v-model="configs.exibir_caixa_limite" autocomplete="off" spellcheck="false">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light">Bloquear apostas do vendedor até prestar contas</label>
                                            <select class="form-control" v-model="configs.bloquear_apostas_vendedor">
                                                <option value="sim" :selected="configs.bloquear_apostas_vendedor == 'sim'">Sim</option>
                                                <option value="nao" :selected="configs.bloquear_apostas_vendedor == 'nao'">Não</option>
                                            </select>
                                        </div>
                                        <div class="pt-2 pb-2">
                                            <h3 class="text-center text-light text-uppercase">Alerta de aposta</h3>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light">E-mail</label>
                                            <input type="email" class="form-control" placeholder="Informe um e-mail" v-model="configs.notificacoes.email" autocomplete="off" spellcheck="false">
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light">Valor</label>
                                            <input type="text" class="form-control" placeholder="Informe um valor" v-model="configs.notificacoes.valor" autocomplete="off" spellcheck="false">
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light">Número de palpites</label>
                                            <input type="text" class="form-control" placeholder="Informe o número de palpites" v-model="configs.notificacoes.palpites" autocomplete="off" spellcheck="false">
                                        </div>
                                    </div><!-- /tab-pane -->
                                    <div class="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                                        <ul class="nav nav-pills nav-justified mb-3" id="myTab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="main-tab" data-toggle="tab" href="#main" role="tab" aria-controls="main" aria-selected="true">Principal</a>
                                            </li>
                                            <li class="nav-item" :class="{'text-truncate': platform == 'mobile'}">
                                                <a class="nav-link" :class="{'text-truncate': platform == 'mobile'}" id="bank-data-tab" data-toggle="tab" href="#bank-data" role="tab" aria-controls="bank-data" aria-selected="false">Dados bancários</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="bonus-tab" data-toggle="tab" href="#bonus" role="tab" aria-controls="bank-data" aria-selected="false">Bônus</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent2">
                                            <div class="tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
                                                <div class="form-group">
                                                    <label class="text-light">Cadastro de clientes</label>
                                                    <select class="form-control" v-model="configs.permitir_cadastro_clientes">
                                                        <option value="1" :selected="configs.permitir_cadastro_clientes == 1">Sim</option>
                                                        <option value="2" :selected="configs.permitir_cadastro_clientes == 2">Não</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Multiplicador rollover</label>
                                                    <input type="text" class="form-control" v-model="configs.rollover" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Saque automático</label>
                                                    <select class="form-control" v-model="configs.saque_automatico">
                                                        <option value="sim" :selected="configs.saque_automatico == 'sim'">Sim</option>
                                                        <option value="nao" :selected="configs.saque_automatico == 'nao'">Não</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Valor mínimo do saque</label>
                                                    <input type="text" class="form-control" v-model="configs.valor_minimo_saque" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Valor mínimo do depósito</label>
                                                    <input type="text" class="form-control" v-model="configs.valor_minimo_deposito" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Valor máximo do saque diário</label>
                                                    <input type="text" class="form-control" v-model="configs.valor_max_saque_diario_cliente" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Valor máximo do saque mensal</label>
                                                    <input type="text" class="form-control" v-model="configs.valor_max_saque_mensal_cliente" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Comissão por indicação (Afiliados)</label>
                                                    <input type="text" class="form-control" v-model="configs.comissao_por_indicacao" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">E-mail para receber as notificações</label>
                                                    <input type="email" class="form-control" v-model="configs.email_notificacoes" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </div>
                                                <div class="form-group">
                                                    <label class="text-light">Mensagem informativa sobre os pagamentos</label>
                                                    <textarea class="form-control" v-model="configs.mensagem_pagamentos" rows="3" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2"></textarea>
                                                </div>
                                            </div><!-- /main-tab-->
                                            <div class="tab-pane fade" id="bank-data" role="tabpanel" aria-labelledby="bank-data-tab">
                                                <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">Selecione o banco</label>
                                                            <div class="w-100">
                                                                <div id="virtual-select" data-style="form-control"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">Tipo de conta</label>
                                                            <select class="form-control" v-model="configs.dados_bancarios.tipo" :disabled="configs.permitir_cadastro_clientes == 2">
                                                                <option value="Corrente" :selected="configs.dados_bancarios.tipo == 'Corrente'">Corrente</option>
                                                                <option value="Poupança" :selected="configs.dados_bancarios.tipo == 'Poupança'">Poupança</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light w-100">
                                                                <span>Tipo de chave pix</span>
                                                                <div class="float-right text-warning">Opcional</div>
                                                            </label>
                                                            <select class="form-control" v-model="configs.dados_bancarios.pix_tipo" :disabled="configs.permitir_cadastro_clientes == 2">
                                                                <option value="cpf_cnpj" :selected="configs.dados_bancarios.pix_tipo == 'cpf_cnpj'">CPF/CNPJ</option>
                                                                <option value="email" :selected="configs.dados_bancarios.pix_tipo == 'email'">E-mail</option>
                                                                <option value="celular" :selected="configs.dados_bancarios.pix_tipo == 'celular'">Celular</option>
                                                                <option value="chave" :selected="configs.dados_bancarios.pix_tipo == 'chave'">Chave aleatória</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light w-100">
                                                                <span>PIX</span>
                                                                <div class="float-right text-warning">Opcional</div>
                                                            </label>
                                                            <template v-if="configs.dados_bancarios.pix_tipo == 'cpf_cnpj'">
                                                                <the-mask class="form-control" v-model="configs.dados_bancarios.pix" :mask="['###.###.###-##', '##.###.###/####-##']" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                                            </template>
                                                            <template v-else-if="configs.dados_bancarios.pix_tipo == 'celular'">
                                                                <the-mask class="form-control" v-model="configs.dados_bancarios.pix" :mask="'(##) # ####-####'" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                                            </template>
                                                            <template v-else>
                                                                <input :type="configs.dados_bancarios.pix_tipo == 'email' ? 'email' : 'text'" class="form-control" v-model="configs.dados_bancarios.pix" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">Agência</label>
                                                            <input type="text" class="form-control" v-model="configs.dados_bancarios.agencia" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">Conta</label>
                                                            <input type="text" class="form-control" v-model="configs.dados_bancarios.conta" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">Dígito</label>
                                                            <input type="text" class="form-control" v-model="configs.dados_bancarios.digito" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">Nome</label>
                                                            <input type="text" class="form-control" v-model="configs.dados_bancarios.nome" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">CPF/CNPJ</label>
                                                            <the-mask class="form-control" v-model="configs.dados_bancarios.cpf_cnpj" :mask="['###.###.###-##', '##.###.###/####-##']" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="text-light">WhatsApp</label>
                                                            <the-mask class="form-control" v-model="configs.dados_bancarios.telefone" :mask="'(##) ####-####'" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div><!-- /bank-tab -->
                                            <div class="tab-pane fade" id="bonus" role="tabpanel" aria-labelledby="bonus-tab">
                                                <ul class="nav nav-pills nav-justified mb-3" id="myTab" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" id="create-bonus-tab" data-toggle="tab" href="#create-bonus" role="tab" aria-controls="create-bonus" aria-selected="true">Novo</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="list-bonus-tab" data-toggle="tab" href="#list-bonus" role="tab" aria-controls="list-bonus" aria-selected="false">Listar</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent3">
                                                    <div class="tab-pane fade show active" id="create-bonus" role="tabpanel" aria-labelledby="create-bonus-tab">
                                                        <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label class="text-light">Código</label>
                                                                    <input type="text" class="form-control" v-model="configs.cupom_promocional_codigo" maxlength="15" placeholder="Crie seu código do cupom" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label class="text-light">Horário de expiração</label>
                                                                    <input type="datetime-local" class="form-control" v-model="configs.cupom_promocional_data" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label class="text-light">{{configs.cupom_promocional_tipo == 'valor' ? 'Valor' : 'Porcentagem'}}</label>
                                                                    <input type="text" class="form-control" v-model="configs.cupom_promocional_valor" :placeholder="configs.cupom_promocional_tipo == 'valor' ? 'Informe o valor' : 'Informe a porcentagem'" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label class="text-light">Tipo de bonificação</label>
                                                                    <select class="form-control" v-model="configs.cupom_promocional_tipo" :disabled="configs.permitir_cadastro_clientes == 2">
                                                                        <option value="porcentagem" :selected="configs.cupom_promocional_tipo == 'porcentagem'">Porcentagem</option>
                                                                        <option value="valor" :selected="configs.cupom_promocional_tipo == 'valor'">Valor</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label class="text-light">Valor mínimo de depósito</label>
                                                                    <input type="text" class="form-control" v-model="configs.cupom_promocional_min_valor" placeholder="Informe o valor" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label class="text-light">Valor máximo convertido do bônus</label>
                                                                    <input type="text" class="form-control" v-model="configs.cupom_promocional_max_valor_convertido" placeholder="Informe o valor" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label class="text-light">Valor máximo do bônus no primeiro depósito</label>
                                                                    <input type="text" class="form-control" v-model="configs.cupom_promocional_max_valor_primeiro_deposito" placeholder="Informe o valor" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><!-- /create-bonus -->
                                                    <div class="tab-pane fade" id="list-bonus" role="tabpanel" aria-labelledby="list-bonus-tab">
                                                        <template v-if="configs.bonus.length == 0">
                                                            <div class="alert alert-info text-center" role="alert">Nenhum bônus criado</div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="card mb-3" v-for="(item, index) in configs.bonus" :key="index">
                                                                <div class="card-header">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <div class="text-uppercase text-danger">{{ item.codigo }}</div>
                                                                        <div>
                                                                            <a class="btn btn-sm btn-block btn-danger" @click="removeBonus(index)" title="Excluir bônus" role="button">
                                                                                <i class="fas fa-trash-alt"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div><!-- /card-header -->
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-sm-4">Código</div>
                                                                        <div class="col-sm-8 text-muted text-uppercase">{{ item.codigo }}</div>
                                                                    </div><!-- /row -->
                                                                    <hr />
                                                                    <div class="row">
                                                                        <div class="col-sm-4">Horário de expiração</div>
                                                                        <div class="col-sm-8 text-muted">{{ item.horario | formatDate }}</div>
                                                                    </div><!-- /row -->
                                                                    <hr />
                                                                    <div class="row">
                                                                        <div class="col-sm-4">Tipo de bonificação</div>
                                                                        <div class="col-sm-8 text-muted text-capitalize">{{ item.tipo }}</div>
                                                                    </div><!-- /row -->
                                                                    <hr />
                                                                    <div class="row">
                                                                        <div class="col-sm-4">{{ item.tipo == 'valor' ? 'Valor' : 'Porcentagem' }} da bonificação</div>
                                                                        <div class="col-sm-8 text-muted">{{ item.valor }}</div>
                                                                    </div><!-- /row -->
                                                                    <hr />
                                                                    <div class="row">
                                                                        <div class="col-sm-4">Valor mínimo de depósito</div>
                                                                        <div class="col-sm-8 text-muted">R$ {{ item.min_valor | formatCoin }}</div>
                                                                    </div><!-- /row -->
                                                                    <hr />
                                                                    <div class="row">
                                                                        <div class="col-sm-4">Valor máximo convertido do bônus</div>
                                                                        <div class="col-sm-8 text-muted">R$ {{ item.max_valor_convertido | formatCoin  }}</div>
                                                                    </div><!-- /row -->
                                                                    <hr />
                                                                    <div class="row">
                                                                        <div class="col-sm-4">Valor máximo do bônus no primeiro depósito</div>
                                                                        <div class="col-sm-8 text-muted">R$ {{ item.max_valor_primeiro_deposito | formatCoin  }}</div>
                                                                    </div><!-- /row -->
                                                                </div><!-- /card-body -->
                                                            </div><!-- /card -->
                                                        </template>
                                                    </div><!-- /list-bonus -->
                                                </div><!-- /tab-content-->
                                            </div><!-- /bonus-tab -->
                                        </div><!-- /tab-content -->
                                    </div>
                                    <div class="tab-pane fade" id="third" role="tabpanel" aria-labelledby="third-tab">
                                        <label class="text-light" for="basic-4">Telegram</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon3">
                                                    <i class="fab fa-telegram"></i>
                                                </span>
                                            </div>
                                            <the-mask class="form-control" id="basic-4" aria-describedby="basic-addon3" v-model="configs.redes_sociais.telegram" :mask="'(##) # ####-####'" placeholder="Informe o número" autocomplete="off" spellcheck="false" />
                                        </div>
                                        <label class="text-light" for="basic-3">Instagram</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon3">
                                                    <i class="fab fa-instagram"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="basic-3" aria-describedby="basic-addon3" v-model="configs.redes_sociais.instagram" placeholder="Informe o usuário" spellcheck="false">
                                        </div>
                                        <label class="text-light" for="basic-4">WhatsApp</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon3">
                                                    <i class="fab fa-whatsapp"></i>
                                                </span>
                                            </div>
                                            <the-mask class="form-control" id="basic-4" aria-describedby="basic-addon3" v-model="configs.redes_sociais.whatsapp" :mask="'(##) # ####-####'" placeholder="Informe o número" autocomplete="off" spellcheck="false" />
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="fourth" role="tabpanel" aria-labelledby="fourth-tab">
                                        <label class="text-light" for="basic-5">Permitir encerramento de apostas</label>
                                        <div class="input-group mb-3">
                                            <select class="form-control" id="basic-5" v-model="configs.encerrar_aposta">
                                                <option value="sim" :selected="configs.encerrar_aposta == 'sim'">Sim</option>
                                                <option value="nao" :selected="configs.encerrar_aposta == 'nao'">Não</option>
                                            </select>
                                        </div>
                                        <label class="text-light" for="basic-6">Porcentagem de encerramento da empresa</label>
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control" id="basic-6" aria-describedby="basic-addon3" v-model="configs.encerrar_aposta_porcentagem" placeholder="Informe a porcentagem">
                                        </div>
                                        <label class="text-light" for="basic-7">Mínimo de palpites pendentes para o encerramento</label>
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control" id="basic-7" aria-describedby="basic-addon3" v-model="configs.encerrar_aposta_num_palpites" placeholder="Informe o número de palpites">
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="fifth" role="tabpanel" aria-labellebdy="fifth-tab">
                                        <label class="text-light" for="basic-5">Ativar loja</label>
                                        <div class="input-group mb-3">
                                            <select class="form-control" id="basic-5" v-model="configs.loja">
                                                <option value="sim" :selected="configs.loja == 'sim'">Sim</option>
                                                <option value="nao" :selected="configs.loja == 'nao'">Não</option>
                                            </select>
                                        </div>
                                        <label class="text-light" for="basic-6">Cashback (R$ 1,00 = {{ configs.loja_cashback }} %)</label>
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control" id="basic-6" aria-describedby="basic-addon3" v-model="configs.loja_cashback" placeholder="Informe a porcentagem do cashback">
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="six" role="tabpanel" aria-labellebdy="six-tab">
                                        <label class="text-light" for="basic-5">Ativar cassino</label>
                                        <div class="input-group mb-3">
                                            <select class="form-control" id="basic-5" v-model="configs.cassino">
                                                <option value="sim" :selected="configs.cassino == 'sim'">Sim</option>
                                                <option value="nao" :selected="configs.cassino == 'nao'">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div><!-- /tab-content -->
                            </template>
                            <template v-if="configs.cupom_promocional_codigo">
                                <a href="javascript:;" class="btn btn-success btn-block" role="button" @click="createBonus">
                                    <i class="fas fa-plus-circle mr-2"></i>Criar bônus
                                </a>
                            </template>
                            <template v-else>
                                <button class="btn btn-warning btn-block" type="submit">
                                    <i class="fas fa-save mr-2"></i>Salvar alterações
                                </button>
                            </template>
                        </form>
                    </div><!-- /mt-3 mb-3 -->
                </template>
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="bg_loading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import {TheMask} from 'vue-the-mask'
import Loading from 'vue-loading-overlay'
import $ from 'jquery/dist/jquery.min.js'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

export default {
    data() {
        return {
            cat: 1,
            error: '',
            banks: [],
            nav_step: 0,
            cfg_step: 0,
            progress: 0,
            showRowID: 0,
            setInterval: '',
            disabledButton: true,
            configs: {
                nome: '',
                bloquear: '',
                min_odd: '',
                min_jogos: '',
                max_jogos: '',
                min_valor: '',
                max_valor: '',
                multiplicador: '',
                permitir_centavos: '',
                exibir_campeonato: '',
                destacar_campeonato: '',
                excluir_aposta_tempo: '',
                gerente_excluir_aposta: '',
                vendedor_excluir_aposta: '',
                max_valor_um_palpite: '',
                max_valor_dois_palpites: '',
                max_valor_mais_palpites: '',
                min_valor_odd_um_palpite: '',
                min_valor_odd_mais_palpites: '',
                regras: '',
                mensagem: '',
                ao_vivo: '',
                ao_vivo_delay: 15,
                ao_vivo_tempo: '',
                ao_vivo_min_jogos: '',
                ao_vivo_opcoes: [],
                max_valor_ao_vivo: '',
                max_jogos_ao_vivo: '',
                min_odd_ao_vivo: '',
                slider: [],
                slider_exibir: '',
                popup_exibir: 'nao',
                popup_items: [],
                data_trava: '',
                exibir_caixa_limite: 15,
                exibir_telefone_vendedor: 1,
                adiar_eventos: 1,
                adiar_eventos_tempo: 24,
                exibir_eventos_limite: 3,
                permitir_cadastro_clientes: 2,
                valor_minimo_saque: 100,
                valor_minimo_deposito: 50,
                links_de_pagamentos: [],
                email_notificacoes: '',
                mensagem_pagamentos: '',
                comissao_por_indicacao: 10,
                encerrar_aposta: 'nao',
                encerrar_aposta_porcentagem: 0,
                encerrar_aposta_num_palpites: 1,
                saque_automatico: 'nao',
                valor_max_saque_diario_cliente: 2000,
                valor_max_saque_mensal_cliente: 20000,
                cupom_promocional_codigo: '',
                cupom_promocional_data: '',
                cupom_promocional_valor: 0,
                cupom_promocional_tipo: 'porcentagem',
                cupom_promocional_min_valor: 100,
                cupom_promocional_max_valor_convertido: 1000,
                cupom_promocional_max_valor_primeiro_deposito: 500,
                notificacoes: {
                    email: '',
                    valor: 50,
                    palpites: 1
                },
                redes_sociais: {
                    twitter: '',
                    telegram: '',
                    facebook: '',
                    whatsapp: '',
                    instagram: ''
                },
                dados_bancarios: {
                    pix: '',
                    pix_tipo: 'cpf_cnpj',
                    nome: '',
                    tipo: '',
                    cpf_cnpj: '',
                    agencia: '',
                    conta: '',
                    digito: '',
                    banco: '',
                    telefone: ''
                },
                bonus: [],
                rollover: 2,
                rollover_saque: 2,
                cassino: 'nao',
                comissao_premio: 0,
                liberar_eventos_automatico: 'nao',
                loja: 'nao',
                loja_cashback: 1,
                loja_images: [{
                    image: '/images/cashback.jpg'
                }],
                betbuilder: 'nao',
                betbuilder_live: 'nao',
                betbuilder_odds: {},
                betbuilder_mercados: [],
                betbuilder_min_jogos: 1,
                betbuilder_max_jogos: 5,
                betbuilder_min_palpites: 2,
                betbuilder_max_palpites: 4
            },
            upload_step: 0,
            loading: false,
            bg_loading: false,
            upload_progress: false,
            betBuilderOptions: {
                options: {
                    1: {
                        label: 'Casa',
                        percent: 0
                    },
                    2: {
                        label: 'Empate',
                        percent: 0
                    },
                    3: {
                        label: 'Fora',
                        percent: 0
                    },
                    4: {
                        label: 'Dupla possibilidade - Casa ou empate',
                        percent: 0
                    },
                    5: {
                        label: 'Dupla possibilidade - Fora ou empate',
                        percent: 0
                    },
                    6: {
                        label: 'Dupla possibilidade - Casa ou fora',
                        percent: 0
                    },
                    11: {
                        label: 'Ambas equipes marcam - Sim',
                        percent: 0
                    },
                    12: {
                        label: 'Ambas equipes marcam - Não',
                        percent: 0
                    },
                    13: {
                        label: 'Partida - Total de gols acima de 0.5',
                        percent: 0
                    },
                    14: {
                        label: 'Partida - Total de gols acima de 1.5',
                        percent: 0
                    },
                    15: {
                        label: 'Partida - Total de gols acima de 2.5',
                        percent: 0
                    },
                    16: {
                        label: 'Partida - Total de gols acima de 3.5',
                        percent: 0
                    },
                    17: {
                        label: 'Partida - Total de gols acima de 4.5',
                        percent: 0
                    },
                    18: {
                        label: 'Partida - Total de gols acima de 5.5',
                        percent: 0
                    },
                    19: {
                        label: 'Casa vence - Fora marca',
                        percent: 0
                    },
                    20: {
                        label: 'Fora vence - Casa marca',
                        percent: 0
                    },
                    21: {
                        label: 'Empate com gols',
                        percent: 0
                    },
                    22: {
                        label: 'Empate sem gols',
                        percent: 0
                    },
                    23: {
                        label: 'Casa vence de zero',
                        percent: 0
                    },
                    24: {
                        label: 'Fora vence de zero',
                        percent: 0
                    },
                    25: {
                        label: 'Placar do jogo - Par',
                        percent: 0
                    },
                    26: {
                        label: 'Placar do jogo - Ímpar',
                        percent: 0
                    },
                    27: {
                        label: 'Placar exato',
                        percent: 0
                    },
                    71: {
                        label: 'Total de gols exato na partida: 0 gol',
                        percent: 0
                    },
                    72: {
                        label: 'Total de gols exato na partida: 1 gol',
                        percent: 0
                    },
                    73: {
                        label: 'Total de gols exato na partida: 2 gols',
                        percent: 0
                    },
                    74: {
                        label: 'Total de gols exato na partida: 3 gols',
                        percent: 0
                    },
                    75: {
                        label: 'Total de gols exato na partida: 4 gols',
                        percent: 0
                    },
                    76: {
                        label: 'Total de gols exato na partida: 5 gols',
                        percent: 0
                    },
                    77: {
                        label: 'Total de gols exato na partida: 6 gols',
                        percent: 0
                    },
                    78: {
                        label: 'Total de gols exato na partida: +7 gols',
                        percent: 0
                    },
                    79: {
                        label: 'Ambas marcam 1º e 2º tempo: Sim/Sim',
                        percent: 0
                    },
                    80: {
                        label: 'Ambas marcam 1º e 2º tempo: Sim/Não',
                        percent: 0
                    },
                    81: {
                        label: 'Ambas marcam 1º e 2º tempo: Não/Sim',
                        percent: 0
                    },
                    82: {
                        label: 'Ambas marcam 1º e 2º tempo: Não/Não',
                        percent: 0
                    },
                    83: {
                        label: 'Tempo com mais gols: 1º tempo',
                        percent: 0
                    },
                    84: {
                        label: 'Tempo com mais gols: 2º tempo',
                        percent: 0
                    },
                    85: {
                        label: 'Tempo com mais gols: Empatado',
                        percent: 0
                    },
                    86: {
                        label: 'Total de gols exatos casa: 0 gol',
                        percent: 0
                    },
                    87: {
                        label: 'Total de gols exatos casa: 1 gol',
                        percent: 0
                    },
                    88: {
                        label: 'Total de gols exatos casa: 2 gols',
                        percent: 0
                    },
                    89: {
                        label: 'Total de gols exatos casa: +3 gols',
                        percent: 0
                    },
                    90: {
                        label: 'Total de gols exatos fora: 0 gol',
                        percent: 0
                    },
                    91: {
                        label: 'Total de gols exatos fora: 1 gol',
                        percent: 0
                    },
                    92: {
                        label: 'Total de gols exatos fora: 2 gols',
                        percent: 0
                    },
                    93: {
                        label: 'Total de gols exatos fora: +3 gols',
                        percent: 0
                    },
                    94: {
                        label: 'Time da casa - Gols par',
                        percent: 0
                    },
                    95: {
                        label: 'Time da casa - Gols ímpar',
                        percent: 0
                    },
                    96: {
                        label: 'Time fora - Gols par',
                        percent: 0
                    },
                    97: {
                        label: 'Time fora - Gols ímpar',
                        percent: 0
                    },
                    98: {
                        label: 'Jogador - Marca primeiro gol',
                        percent: 0
                    },
                    99: {
                        label: 'Jogador - Marca gol na partida',
                        percent: 0
                    },
                    236: {
                        label: 'Jogador - Marca último gol',
                        percent: 0
                    },
                    100: {
                        label: 'Escanteios - Mais',
                        percent: 0
                    },
                    101: {
                        label: 'Escanteios - Menos',
                        percent: 0
                    },
                    102: {
                        label: 'Escanteios - Exatos',
                        percent: 0
                    },
                    123: {
                        label: 'Número de cartões na partida - Acima',
                        percent: 0
                    },
                    124: {
                        label: 'Número de cartões na partida - Abaixo',
                        percent: 0
                    },
                    125: {
                        label: 'Número de cartões da casa - Acima',
                        percent: 0
                    },
                    126: {
                        label: 'Número de cartões da casa - Abaixo',
                        percent: 0
                    },
                    127: {
                        label: 'Número de cartões do fora - Acima',
                        percent: 0
                    },
                    128: {
                        label: 'Número de cartões do fora - Abaixo',
                        percent: 0
                    },
                    182: {
                        label: 'Partida - Total de gols abaixo de 0.5',
                        percent: 0
                    },
                    183: {
                        label: 'Partida - Total de gols abaixo de 1.5',
                        percent: 0
                    },
                    184: {
                        label: 'Partida - Total de gols abaixo de 2.5',
                        percent: 0
                    },
                    185: {
                        label: 'Partida - Total de gols abaixo de 3.5',
                        percent: 0
                    },
                    186: {
                        label: 'Partida - Total de gols abaixo de 4.5',
                        percent: 0
                    },
                    187: {
                        label: 'Partida - Total de gols abaixo de 5.5',
                        percent: 0
                    }
                },
                ids: [1, 2, 3, 4, 5, 6, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 123, 124, 125, 126, 127, 128, 182, 183, 184, 185, 186, 187, 236]
            },
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        TheMask,
        Loading
    },
    methods: {
        save() {
            
            const self = this;
            const regExp = /[a-zA-Z]/g;
            const filter = self.configs.ao_vivo_opcoes.some(item => item.limite.toString().trim().length == 0 || item.porcentagem.toString().trim().length == 0 || regExp.test(item.limite.toString().trim()) || regExp.test(item.porcentagem.toString().trim()));

            if (filter || self.configs.nome.toString().trim() == '' || self.configs.multiplicador.toString().trim() == '' || self.configs.min_jogos.toString().trim() == '' || self.configs.max_jogos.toString().trim() == '' || self.configs.min_valor.toString().trim() == '' || self.configs.max_valor.toString().trim() == '' || self.configs.max_valor_um_palpite.toString().trim() == '' || self.configs.max_valor_dois_palpites.toString().trim() == '' || self.configs.max_valor_mais_palpites.toString().trim() == '' || self.configs.min_valor_odd_um_palpite.toString().trim() == '' || self.configs.min_valor_odd_mais_palpites.toString().trim() == '' || self.configs.excluir_aposta_tempo.toString().trim() == '' || self.configs.mensagem.trim() == '' || self.configs.regras.trim() == '' || self.configs.ao_vivo_tempo.toString().trim() == '' || self.configs.data_trava.toString().trim() == '' || self.configs.exibir_caixa_limite.toString().trim() == '' || self.configs.min_odd.toString().trim() == '' || self.configs.betbuilder_min_palpites.toString().trim() == '' || self.configs.betbuilder_max_palpites.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Preencha todos os campos antes de salvar!',
                    'warning'
                );
            }
            else if (self.configs.permitir_cadastro_clientes == '1' && (self.configs.email_notificacoes.trim() == '' || self.configs.mensagem_pagamentos.trim() == '' || self.configs.dados_bancarios.banco == '' || self.configs.dados_bancarios.nome == '' || self.configs.dados_bancarios.agencia == '' || self.configs.dados_bancarios.conta == '' || self.configs.dados_bancarios.digito == '' || self.configs.dados_bancarios.cpf_cnpj == '' || self.configs.dados_bancarios.telefone == '')) {
                Swal.fire(
                    'Atenção!',
                    'Preencha todos os campos antes de salvar!',
                    'warning'
                );
            }
            else if (self.configs.permitir_cadastro_clientes == '1' && !self.validateEmail(self.configs.email_notificacoes)) {
                Swal.fire(
                    'Atenção!',
                    'O endereço de e-mail fornecido é inválido!',
                    'warning'
                );
            }
            else if (self.configs.notificacoes.email.toString().trim() != '' && !self.validateEmail(self.configs.notificacoes.email)) {
                Swal.fire(
                    'Atenção!',
                    'O endereço de e-mail fornecido em alertas de aposta é inválido!',
                    'warning'
                );
            }
            else if (self.configs.notificacoes.valor.toString().trim() == '' || self.configs.notificacoes.valor < 20) {
                Swal.fire(
                    'Atenção!',
                    'O campo "valor" em alertas de aposta não pode ser menor que R$ 20,00!',
                    'warning'
                );
            }
            else if (self.configs.notificacoes.palpites.toString().trim() == '' || self.configs.notificacoes.palpites == 0) {
                Swal.fire(
                    'Atenção!',
                    'O campo "número de palpites" em alertas de aposta não pode ficar em branco!',
                    'warning'
                );
            }
            else if (parseInt(self.configs.ao_vivo_delay) < 5 || parseInt(self.configs.ao_vivo_delay) > 30)
            {
                Swal.fire(
                    'Atenção!',
                    'Por questões de segurança, recomendamos que o delay do ao vivo não seja menor que 5 segundos e nem maior que 30 segundos!',
                    'warning'
                );
            }
            else if (self.configs.slider.length == 0)
            {
                Swal.fire(
                    'Atenção!',
                    'O slider não pode ficar sem imagem, envie pelo menos uma!',
                    'warning'
                );
            }
            else if (self.configs.slider.length > 10)
            {
                Swal.fire(
                    'Atenção!',
                    'Envie no máximo 10 imagens no slider!',
                    'warning'
                );
            }
            else if (self.configs.rollover.toString().trim() == '')
            {
                Swal.fire(
                    'Atenção!',
                    'Informe o multiplicador do rollover!',
                    'warning'
                );
            }
            else if (self.configs.loja_cashback.toString().trim() == '')
            {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor do cashback!',
                    'warning'
                );
            }
            else if (self.configs.valor_max_saque_diario_cliente.toString().trim() == '')
            {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor máximo de saque diário do cliente!',
                    'warning'
                );
            }
            else if (self.configs.valor_max_saque_mensal_cliente.toString().trim() == '')
            {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor máximo de saque mensal do cliente!',
                    'warning'
                );
            }
            else if (parseInt(self.configs.betbuilder_min_palpites) < 2)
            {
                Swal.fire(
                    'Atenção!',
                    'O mínimo de seleção por jogo na opção "Criar aposta" não pode ser menor que 2.',
                    'warning'
                );
            }
            else if (parseInt(self.configs.betbuilder_max_palpites) > parseInt(self.configs.max_jogos))
            {
                Swal.fire(
                    'Atenção!',
                    `O máximo de seleção por jogo na opção "Criar aposta" não pode ser maior que ${self.configs.max_jogos}.`,
                    'warning'
                );
            }
            else if (!self.isValidBetBuilderOptions()) 
            {
                Swal.fire(
                    'Atenção!',
                    `Não deixe o campo "Reduzir (%)" em branco, caso queira deixar o valor original da ODD no "Criar aposta", basta informar o número "0" no campo "Reduzir (%)".`,
                    'warning'
                );
            }
            else 
            {
                self.bg_loading = true;

                api.post('dashboard/configs', {
                    configs: self.configs
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.bg_loading = false;
                });
            }
        },
        isValidBetBuilderOptions() {

            let isValid = true;

            for (let key in this.betBuilderOptions.options) {
                let option = this.betBuilderOptions.options[key];
                if (option.percent.toString().trim() == '') {
                    isValid = false;
                    break;
                }
            }

            return isValid;
        },
        createBonus() {
            if (this.configs.cupom_promocional_codigo.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe um código para a criação do cupom!',
                    'warning'
                );
            } else if (this.configs.cupom_promocional_data == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o horário de expiração do cupom!',
                    'warning'
                );
            } else if (this.configs.cupom_promocional_valor == 0) {
                Swal.fire(
                    'Atenção!',
                    `Informe ${this.configs.cupom_promocional_tipo == 'valor' ? 'o valor' : 'a porcentagem'} do cupom!`,
                    'warning'
                );
            }else if (this.configs.cupom_promocional_min_valor.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor mínimo de depósito para converter em do bônus!',
                    'warning'
                );
            }  else if (this.configs.cupom_promocional_max_valor_convertido.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor máximo convertido do bônus!',
                    'warning'
                );
            } else if (this.configs.cupom_promocional_max_valor_primeiro_deposito.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor máximo do bônus no primeiro depósito!',
                    'warning'
                );
            } else {

                if (this.configs.bonus.length == 0) {
                    this.configs.bonus.push({
                        codigo: this.configs.cupom_promocional_codigo,
                        horario: this.configs.cupom_promocional_data,
                        valor: this.configs.cupom_promocional_valor,
                        tipo: this.configs.cupom_promocional_tipo,
                        min_valor: this.configs.cupom_promocional_min_valor,
                        max_valor_convertido: this.configs.cupom_promocional_max_valor_convertido,
                        max_valor_primeiro_deposito: this.configs.cupom_promocional_max_valor_primeiro_deposito
                    });
                } else if (this.configs.bonus.some(item => item.codigo == this.configs.cupom_promocional_codigo)) {
                    Swal.fire(
                        'Atenção!',
                        `Já existe um bônus com o código <b>${this.configs.cupom_promocional_codigo}</b>. Informe um diferente e tente novamente!`,
                        'warning'
                    );
                } else {

                    this.configs.bonus.push({
                        codigo: this.configs.cupom_promocional_codigo,
                        horario: this.configs.cupom_promocional_data,
                        valor: this.configs.cupom_promocional_valor,
                        tipo: this.configs.cupom_promocional_tipo,
                        min_valor: this.configs.cupom_promocional_min_valor,
                        max_valor_convertido: this.configs.cupom_promocional_max_valor_convertido,
                        max_valor_primeiro_deposito: this.configs.cupom_promocional_max_valor_primeiro_deposito
                    });

                    Swal.fire(
                        'Parabéns!',
                        'Novo bônus criado com sucesso!<hr/>Não esqueça de apertar em <b>SALVAR ALTERAÇÕES</b>, para que o mesmo fique ativo.',
                        'success'
                    );
                }
            }
        },
        removeBonus(index) {
            try {
                Swal.fire({
                title: 'Excluir bônus',
                text: 'Você deseja mesmo excluir o bônus?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar',
                }).then((result) => {
                    if (result.value) {

                        this.configs.bonus.splice(index, 1);

                        Swal.fire(
                            'Parabéns!',
                            'O bônus foi excluído com sucesso!<hr/>Não esqueça de apertar em <b>SALVAR ALTERAÇÕES</b>, para que o mesmo seja removido completamente.',
                            'success'
                        )
                    }
                });
            } catch(e) {
                Swal.fire(
                    'Atenção!',
                    'Ocorreu um erro ao tentar excluir o bônus, atualiza a página e tente novamente!',
                    'warning'
                );
            }
        },
        upload() {
           
           const self = this;
           const img = new Image();
           const imageDimensionsAllowed = ['597x250','250x400'];
           const imagem = document.getElementById('image-upload').files[0];
           const imageTypes = ['image/jpg','image/jpeg','image/gif','image/png'];
           
           img.onload = () => {

                const imageDimensions = `${img.naturalWidth}x${img.naturalHeight}`;

                if (typeof(imagem) == 'object') {

                    if (!imageTypes.includes(imagem.type)) {
                        Swal.fire(
                            'Atenção!',
                            'O formato da imagem é inválido!',
                            'error'
                        );
                    } else if (!imageDimensionsAllowed.includes(imageDimensions)) {
                        Swal.fire(
                            'Atenção!',
                            'Envie a imagem com as dimensões exatas que o sistema pede!',
                            'error'
                        );
                    }
                    else 
                    {
                        self.progress = 0;
                        self.upload_progress = true;

                        let formData = new FormData();
                            formData.append('imagem', imagem);

                        api.post('dashboard/upload', formData , {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            transformRequest: [(data) => {
                                return data
                            }],
                            onUploadProgress: (progressEvent) => {
                                let totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                                if (totalLength !== null) {
                                    self.progress = Math.round((progressEvent.loaded * 100) / totalLength);
                                }
                            }
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    if (self.upload_step == 0) {
                                        self.configs.slider.push({
                                            link: '',
                                            image: response.data.file
                                        });
                                    } else {
                                        self.configs.popup_items.push({
                                            link: '',
                                            image: response.data.file
                                        });
                                    }
                                break;
                                default:
                                    Swal.fire(
                                        'Atenção',
                                        response.data.message,
                                        'error'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            } 
                        }).finally(() => {
                            self.upload_progress = false;
                        });
                    }
                }
           };

           img.src = URL.createObjectURL(imagem);
        },
        getBanks() {

            const self = this;

            if (self.banks.length) return;

            self.bg_loading = true;

            api.get('banks').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.banks = response.data.banks.sort((a, b) => {
                            return a.Name.localeCompare(b.Name);
                        }).map((item) => {
                            return {
                                Name: [item.Code, item.Name].join(' - ')
                            }
                        });
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'error'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.bg_loading = false;
            });
        },
        deleteImage(index) {

            Swal.fire({
                title: 'Excluir imagem',
                text: 'Você deseja mesmo excluir essa imagem?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.value) {

                    if (this.upload_step == 0) {
                        this.configs.slider.splice(index, 1);
                    } else {
                        this.configs.popup_items.splice(index, 1);
                    }
            
                    Swal.fire(
                        'Parabéns!',
                        'A imagem foi excluída com sucesso!',
                        'success'
                    )
                }
            });
        },
        async linkImage(index) {

            try {

                const { value: link } = await Swal.fire({
                    title: 'Adicionar link',
                    html: `Você está adicionando um link na imagem de posição número: ${index + 1}`,
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    customClass: 'sweet-only-custom',
                    inputPlaceholder: 'Informe o link',
                    confirmButtonText: 'Adicionar',
                    inputValidator: (value) => {
                        if (value.toString().trim()) {
                            if (!value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._/+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_/+.~#?&//=]*)/g)) {
                                return 'O link informado não é válido!'
                            }
                        }
                    }
                });

                if (link) {
                    if (this.upload_step == 0) {
                        this.configs.slider[index].link = link;
                    } else {
                        this.configs.popup_items[index].link = link;
                    }
                } else {
                    if (this.upload_step == 0) {
                        this.configs.slider[index].link = '';
                    } else {
                        this.configs.popup_items[index].link = '';
                    }
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha!',
                    'Não é possível adicionar um link nessa imagem!',
                    'error'
                );
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        validateEmail(mail) {
            if (/\S+@\S+\.\S+/.test(mail)) {
                return true
            } else {
                return false;
            }
        },
        noPaste(event) {
            return event.preventDefault();
        },
        virtualSelect(items) {
            window.VirtualSelect.init({
                ele: '#virtual-select',
                search: true,
                placeholder: 'Selecione um banco',
                noOptionsText: 'Nenhuma opção disponível',
                noSearchResultsText: 'Nenhum resultado encontrado',
                searchPlaceholderText: `Buscar banco... (${items.length})`,
                selectedValue: this.configs.dados_bancarios.banco,
                hasOptionDescription: true,
                options: items.map((item) => {
                    return {
                        label: item.Name,
                        value: item.Name
                    }
                })
            });
        },
        async addPaymentLink() {

            try {

                const self = this;

                const { value: formValues } = await Swal.fire({
                    title: 'Adicionar link',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <label class="d-block text-left mb-0">Valor</label>
                        <input id="swal-input1" class="swal2-input" autocomplete="off" spellcheck="false" placeholder="Ex: 50">

                        <label class="d-block text-left mb-0">Insira o link</label>
                        <input id="swal-input2" class="swal2-input" autocomplete="off" spellcheck="false" placeholder="Ex: https://mpago.la/1xUS1">
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            valor: document.getElementById('swal-input1').value,
                            link: document.getElementById('swal-input2').value,
                        }
                    },
                    onOpen: () => {

                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                            return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input2").addEventListener("input", (e) => {
                            if (!e.target.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._/+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_/+.~#?&//=]*)/g)) {
                                return e.target.value = '';
                            } else {
                                return e.target.value;
                            }
                        });
                    }
                });

                if (formValues.valor == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o valor do link!',
                        'warning'
                    );
                } else if (!formValues.link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._/+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_/+.~#?&//=]*)/g)) {
                    Swal.fire(
                        'Atenção!',
                        'O link de pagamento é inválido!',
                        'warning'
                    );
                } else {
                    
                    if (self.configs.links_de_pagamentos.length == 0) {
                        self.configs.links_de_pagamentos.push({
                            valor: formValues.valor,
                            link: formValues.link
                        });
                    } else {
                        if (self.configs.links_de_pagamentos.some((item) => item.valor == formValues.valor)) {
                            Swal.fire(
                                'Atenção!',
                                'Não é permitido adicionar mais de um link com o mesmo valor!',
                                'warning'
                            );
                        } else {
                            self.configs.links_de_pagamentos.push({
                                valor: formValues.valor,
                                link: formValues.link
                            });
                        }
                    }
                }
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        deletePaymentLink(index, valor) {
            Swal.fire({
                title: 'Excluir link',
                text: `Você deseja mesmo excluir o link de R$ ${this.formatCoin(valor)} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.value) {

                    this.configs.links_de_pagamentos.splice(index, 1);

                    Swal.fire(
                        'Parabéns!',
                        'O link foi excluído com sucesso!',
                        'success'
                    )
                }
            });
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		},
        releaseGames() {
            const day = moment().format('ddd').toLowerCase();
            const data_trava = this.configs.data_trava ? this.configs.data_trava : moment().format('YYYY-MM-DD');
            const nextMonday = moment(data_trava).weekday(7).format('YYYY-MM-DD');

            if (day == 'sun' || day == 'mon') {
                if (this.setInterval) clearInterval(this.setInterval);
                this.disabledButton = true;
                this.configs.data_trava = nextMonday;
                this.save();
            } else {
                Swal.fire(
                    'Atenção!',
                    'Essa opção só é liberada domingo ou segunda-feira!',
                    'warning'
                );
            }
        },
        toggleShowRow(id) {
            const allowedIds = [100, 101, 102];
            if (allowedIds.includes(id)) {
                if (this.showRowID == id) {
                    this.showRowID = 0;
                } else {
                    this.showRowID = id;
                }
            } else {
                this.showRowID = 0;
            }
        },
        selectOptionMarket(optionId) {
            const option_id = parseInt(optionId);
            if (this.betBuilderOptions.ids.includes(option_id)) {
                this.betBuilderOptions.ids.splice(this.betBuilderOptions.ids.indexOf(option_id), 1);
            } else {
                this.betBuilderOptions.ids.push(option_id);
            }
        },
        selectedOptionMarket(option_id) {
            return this.betBuilderOptions.ids.includes(parseInt(option_id));
        }
    },
    computed: {
        nome() {
            return this.configs.nome;
        },
        multiplicador() {
            return this.configs.multiplicador;
        },
        rollover() {
            return this.configs.rollover;
        },
        lojaCashback() {
            return this.configs.loja_cashback;
        },
        minJogos() {
            return this.configs.min_jogos;
        },
        maxJogos() {
            return this.configs.max_jogos;
        },
        valorMinimo() {
            return this.configs.min_valor;
        },
        valorMaximo() {
            return this.configs.max_valor;
        },
        maxJogosAovivo() {
            return this.configs.max_jogos_ao_vivo;
        },
        valorMinimoAovivo() {
            return this.configs.min_valor_ao_vivo;
        },
        valorMaximoAovivo() {
            return this.configs.max_valor_ao_vivo;
        },
        valorMaximoUmPalpite() {
            return this.configs.max_valor_um_palpite;
        },
        valorMaximoDoisPalpites() {
            return this.configs.max_valor_dois_palpites;
        },
        valorMaximoMaisPalpites() {
            return this.configs.max_valor_mais_palpites;
        },
        minValorOddUmPalpite() {
            return this.configs.min_valor_odd_um_palpite;
        },
        minValorOddMaisPalpites() {
            return this.configs.min_valor_odd_mais_palpites;
        },
        minValorOdd() {
            return this.configs.min_odd;
        },
        minValorOddUmPalpiteAovivo() {
            return this.configs.min_valor_odd_um_palpite_ao_vivo;
        },
        minValorOddMaisPalpitesAovivo() {
            return this.configs.min_valor_odd_mais_palpites_ao_vivo;
        },
        minValorOddAovivo() {
            return this.configs.min_odd_ao_vivo;
        },
        excluirApostaTempo() {
            return this.configs.excluir_aposta_tempo;
        },
        mensagem() {
            return this.configs.mensagem;
        },
        regras() {
            return this.configs.regras;
        },
        aoVivoTempo() {
            return this.configs.ao_vivo_tempo;
        },
        aoVivoMinJogos() {
            return this.configs.ao_vivo_min_jogos;
        },
        adiarEventosTempo() {
            return this.configs.adiar_eventos_tempo;
        },
        exibirEventosLimite() {
            return this.configs.exibir_eventos_limite;
        },
        exibirCaixaLimite() {
            return this.configs.exibir_caixa_limite;
        },
        permitirCadastroClientes() {
            return this.configs.permitir_cadastro_clientes;
        },
        dadosBancariosConta() {
            return this.configs.dados_bancarios.conta;
        },
        dadosBancariosAgencia() {
            return this.configs.dados_bancarios.agencia;
        },
        dadosBancariosDigito() {
            return this.configs.dados_bancarios.digito;
        },
        dadosBancariosNome() {
            return this.configs.dados_bancarios.nome;
        },
        dadosBancariosBanco() {
            return this.configs.dados_bancarios.banco;
        },
        valorMinimoSaque() {
            return this.configs.valor_minimo_saque;
        },
        valorMinimoDeposito() {
            return this.configs.valor_minimo_deposito;
        },
        pix() {
            return this.configs.dados_bancarios.pix;
        },
        instagram() {
            return this.configs.redes_sociais.instagram;
        },
        encerrarApostaPorcentagem() {
            return this.configs.encerrar_aposta_porcentagem;
        },
        encerrarApostaNumPalpites() {
            return this.configs.encerrar_aposta_num_palpites;
        },
        notificacoesEmail() {
            return this.configs.notificacoes.email;
        },
        notificacoesValor() {
            return this.configs.notificacoes.valor;
        },
        notificacoesPalpites() {
            return this.configs.notificacoes.palpites;
        },
        comissaoPorIndicacao() {
            return this.configs.comissao_por_indicacao;
        },
        cupomPromocionalCodigo() {
            return this.configs.cupom_promocional_codigo;
        },
        cupomPromocionalValor() {
            return this.configs.cupom_promocional_valor;
        },
        aoVivoDelay() {
            return this.configs.ao_vivo_delay;
        },
        cupomPromocionalMinValorConvertido() {
            return this.configs.cupom_promocional_min_valor;
        },
        cupomPromocionalMaxValorConvertido() {
            return this.configs.cupom_promocional_max_valor_convertido;
        },
        cupomPromocionalMaxValorPrimeiroDeposito() {
            return this.configs.cupom_promocional_max_valor_primeiro_deposito;
        },
        valorMaxSaqueDiarioCliente() {
            return this.configs.valor_max_saque_diario_cliente;
        },
        valorMaxSaqueMensalCliente() {
            return this.configs.valor_max_saque_mensal_cliente;
        }
    },
    watch: {
        pix(value) {
            try {
                this.configs.dados_bancarios.pix = value.trim();
            } catch (e) {
                //
            }
        },
        instagram(value) {
            try {
                this.configs.redes_sociais.instagram = value.replace(/[^a-z0-9._]/g, '');
            } catch(e) {
                //
            }
        },
        nome(value) {
            try {
                this.configs.nome = value.replace(/[^a-zà-ú0-9\-+\s]+/gi, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        multiplicador(value) {
            try {
                this.configs.multiplicador = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        rollover(value) {
            try {
                this.configs.rollover = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaxSaqueDiarioCliente(value) {
            try {
                this.configs.valor_max_saque_diario_cliente = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaxSaqueMensalCliente(value) {
            try {
                this.configs.valor_max_saque_mensal_cliente = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        lojaCashback(value) {
            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
                this.configs.loja_cashback = value;
            } catch(e) {
                this.configs.loja_cashback = 1;
            }
        },
        minJogos(value) {
            try {
                this.configs.min_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        maxJogos(value) {
            try {
                this.configs.max_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        maxJogosAovivo(value) {
            try {
                this.configs.max_jogos_ao_vivo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        comissaoPorIndicacao(value) {
            try {
                this.configs.comissao_por_indicacao = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMinimo(value) {
            try {
                this.configs.min_valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximo(value) {
            try {
                this.configs.max_valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMinimoAovivo(value) {
            try {
                this.configs.min_valor_ao_vivo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximoAovivo(value) {
            try {
                this.configs.max_valor_ao_vivo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximoUmPalpite(value) {
            try {
                this.configs.max_valor_um_palpite = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximoDoisPalpites(value) {
            try {
                this.configs.max_valor_dois_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximoMaisPalpites(value) {
            try {
                this.configs.max_valor_mais_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOdd(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 

                this.configs.min_odd = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOddUmPalpite(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 

                this.configs.min_valor_odd_um_palpite = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOddMaisPalpites(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2");

                this.configs.min_valor_odd_mais_palpites = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOddAovivo(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 

                this.configs.min_odd_ao_vivo = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOddUmPalpiteAovivo(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 

                this.configs.min_valor_odd_um_palpite_ao_vivo = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOddMaisPalpitesAovivo(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2");

                this.configs.min_valor_odd_mais_palpites_ao_vivo = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        excluirApostaTempo(value) {
            try {
                this.configs.excluir_aposta_tempo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        mensagem(value) {
            try {
                this.configs.mensagem = value.replace(/[^a-zà-ú0-9(!.,)\-\s]+/gi, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        regras(value) {
            try {
                this.configs.regras = value.replace(/[^a-zà-ú0-9(!.,/)\-\s]+/gi, ''); 
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        aoVivoTempo(value) {
            try {
                this.configs.ao_vivo_tempo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        aoVivoMinJogos(value) {
            try {
                this.configs.ao_vivo_min_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        adiarEventosTempo(value) {
            try {
                this.configs.adiar_eventos_tempo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        exibirEventosLimite(value) {
            try {
                this.configs.exibir_eventos_limite = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        exibirCaixaLimite(value) {
            try {
                this.configs.exibir_caixa_limite = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        // permitirCadastroClientes(value) {
        //     if (value == 1 && this.banks.length == 0) {
        //         this.getBanks();
        //     }
        // },
        dadosBancariosConta(value) {
            try {
                this.configs.dados_bancarios.conta = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosAgencia(value) {
            try {
                this.configs.dados_bancarios.agencia = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosDigito(value) {
            try {
                this.configs.dados_bancarios.digito = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosNome(value) {
            try {
                this.configs.dados_bancarios.nome = value.replace(/[^a-zà-ú\s]+/gi, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMinimoSaque(value) {
            try {
                this.configs.valor_minimo_saque = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMinimoDeposito(value) {
            try {
                this.configs.valor_minimo_deposito = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        encerrarApostaPorcentagem(value) {
            try {
                this.configs.encerrar_aposta_porcentagem = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        encerrarApostaNumPalpites(value) {
            try {
                this.configs.encerrar_aposta_num_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        notificacoesEmail(value) {
            try {
                this.configs.notificacoes.email = value.replace(/\s+/g, '');
            } catch(e) {    
                window.console.log(e.message);
            }
        },
        notificacoesValor(value) {
            try {
                this.configs.notificacoes.valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        notificacoesPalpites(value) {
            try {
                this.configs.notificacoes.palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        cupomPromocionalCodigo(value) {
            try {
                this.configs.cupom_promocional_codigo = value.toString().toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        cupomPromocionalValor(value) {
            try {
                this.configs.cupom_promocional_valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        aoVivoDelay(value) {
            try {
                this.configs.ao_vivo_delay = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        cupomPromocionalMinValorConvertido(value) {
            try {
                this.configs.cupom_promocional_min_valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        cupomPromocionalMaxValorConvertido(value) {
            try {
                this.configs.cupom_promocional_max_valor_convertido = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        cupomPromocionalMaxValorPrimeiroDeposito(value) {
            try {
                this.configs.cupom_promocional_max_valor_primeiro_deposito = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        banks(value) {
            this.virtualSelect(value);
        },
        cat(value) {
            if (value == 2) {
                this.isLivePageActive = true;
            } else {
                this.isLivePageActive = false;
                if (this.$route.query.hasOwnProperty('nav')) {
                    this.$router.replace({
                        'query.nav': null
                    });
                }
            }
        },
        'configs.bonus'() {
            this.configs.cupom_promocional_data = '';
            this.configs.cupom_promocional_valor = '';
            this.configs.cupom_promocional_codigo = '';
        },
        'configs.comissao_premio'(value) {
            try {
                this.configs.comissao_premio = value.replace(/[^0-9]/g, '');
            } catch(e) {
                this.configs.comissao_premio = 0;
            }
        },
        'configs.betbuilder_min_jogos'(value) {
            try {
                this.configs.betbuilder_min_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                this.configs.betbuilder_min_jogos = 1;
            }
        },
        'configs.betbuilder_max_jogos'(value) {
            try {
                this.configs.betbuilder_max_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                this.configs.betbuilder_max_jogos = 5;
            }
        },
        'configs.betbuilder_min_palpites'(value) {
            try {
                this.configs.betbuilder_min_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                this.configs.betbuilder_min_palpites = 2;
            }
        },
        'configs.betbuilder_max_palpites'(value) {
            try {
                this.configs.betbuilder_max_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                this.configs.betbuilder_max_palpites = 4;
            }
        },
        'configs.betbuilder_odds'(values) {
            const totalValues = Object.keys(values).length;
            const totalValuesOptions = Object.keys(this.betBuilderOptions.options).length;
            if (totalValues == 0) {
                this.configs.betbuilder_odds = this.betBuilderOptions.options;
            } else {
                this.betBuilderOptions.options = (totalValues < totalValuesOptions) ? this.betBuilderOptions.options : values;
            }
        },
        'configs.betbuilder_mercados'(values) {
            this.betBuilderOptions.ids = values;
        },
        'betBuilderOptions.ids'(values) {
            this.configs.betbuilder_mercados = values;
        },
        'betBuilderOptions.options': {
            handler(values) {
                this.configs.betbuilder_odds = values;
            },
            deep: true,
            immediate: true
        },
        '$route': {
            immediate: true,
            handler() {
                if (this.$route.query.hasOwnProperty('nav')) {
                    if (this.$route.query.nav == 'live-odds') {
                        this.cat = 2;
                        this.nav_step = 1;
                        this.isLivePageActive = true;
                    } else {
                        this.isLivePageActive = false;
                        this.$router.replace({
                            'query.nav': null
                        });
                    }
                }
            }
        }
    },
    directives: {
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^0-9.]/g,"");
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/Y [às] HH:mm');
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    mounted() {

        const self = this;
        const days = ['sun','mon'];
        const day = moment().format('ddd').toLowerCase();

        $(document.body).on('change', '#virtual-select', function() {
            self.configs.dados_bancarios.banco = this.value;
        });

        if (days.includes(day)) {
            self.disabledButton = false;
        } else {
            self.disabledButton = true;
        }

        self.setInterval = setInterval(() => {
            if (days.includes(moment().format('ddd').toLowerCase())) {
                self.disabledButton = false;
            } else {
                self.disabledButton = true;
            }
        }, (60 * 1000));
    },
    created() {

        const self = this;

        self.loading = true;

        api.get('dashboard/configs').then((response) => {
            self.configs = response.data.configs;
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.form-control.w-auto {
    width: auto!important;
}
.form-group[role="file"] {
    position: relative;
    z-index: 0;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
input#image-upload {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    visibility: hidden;
    text-align: right;
    opacity: 0;
    outline: none;
    background: none;
    cursor: inherit;
    display: block;
}
.myTabConfigs.nav-pills .nav-link {
    border-radius: 0;
    color: var(--white);
    background-color: var(--dark);
}
.myTabConfigs.nav-pills .nav-link.active {
    background-color: var(--red);
}
.col.pl-0 #virtual-select {
    max-width: 100%!important;
}
.w-100.clearfix.desktop .list-group .list-group-item .row .col-auto input[type="text"]{
    max-width: 100px;
}
.w-100.clearfix .list-group.list-group-flush {
    overflow-x: auto;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track:hover {
  background: #666666;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track:active {
  background: #333333;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-corner {
  background: transparent;
}
.w-100.clearfix .list-group.list-group-flush .col-12 .bg-cat {
    background-color: #e8e8e8;
}
.row.align-items-center .col .btn.btn-block.btn-success {
    margin-top: 31px;
}
</style>